body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.github-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start
}

.github-href {
    padding-right: 30px;
    background-position: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 2px;
    padding-bottom: 2px;
    color: #2a2a2a;
    margin-right: 10px
}

.demo-source {
    display: none
}

pre {
    margin-bottom: 0!important
}

pre code.hljs {
    display: block
}

code.hljs {
    display: inline
}

span.new {
    font-size: 90%;
    color: #dc385f
}

.github-logo {
    width: 40px;
    display: block;
    position: relative
}

.github-logo img {
    max-width: 100%;
    opacity: .9
}

.github-logo img:hover {
    opacity: 1
}

.source {
    width: 100%;
    height: 90vh;
    left: 0;
    top: 0;
    position: relative;
    display: none
}

.source .source-block {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 301;
    overflow: scroll
}

.source .source-block pre {
    display: block;
    position: relative
}

.source .source-block pre code {
    font-size: 90%
}

.demo-block {
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc
}

.flip-book {
    box-shadow: 0 0 20px 0 rgba(0,0,0,.5);
    margin: 0 auto;
    display: none;
    z-index: 100
}

.html-book {
    background-size: cover
}

.page {
    padding: 20px;
    background-color: #fff;
    color: #785e3a;
    border: 1px solid #c2b5a3;
    overflow: hidden
}
.demo-book.stf__parent{
margin:auto;
}
.page .page-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch
}

.page .page-content .page-header {
    height: 30px;
    font-size: 100%;
    text-transform: uppercase;
    text-align: center
}

.page .page-content .page-image {
    height: 100%;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat
}

.page .page-content .page-text {
    height: 100%;
    flex-grow: 1;
    font-size: 80%;
    text-align: justify;
    margin-top: 10px;
    padding-top: 10px;
    box-sizing: border-box;
    border-top: 1px solid #f4e8d7
}

.page .page-content .page-footer {
    height: 30px;
    border-top: 1px solid #ccc;
    font-size: 80%;
    color: #998466
}

.page.--left {
    border-right: 0;
    box-shadow: inset -7px 0 30px -7px rgba(0,0,0,.4)
}

.page.--right {
    border-left: 0;
    box-shadow: inset 7px 0 30px -7px rgba(0,0,0,.4)
}

.page.--right .page-footer {
    text-align: right
}
.page-footer{


position:absolute;
bottom:0;
right:10px;
font-size:12px;
height:20px;
width:70%;
text-align:right;
color: #000;
font-weight:bold;
 -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}
.numHolder{
display:inline-flex;
text-align:center;
padding:2px;
border:2px solid #2e3192;
border-radius:10px;
color:#000;
}
.numHolder div{

margin:auto;
}
.coverImage img{


position:absolute;top:0;height:100%;width:auto;left:0;
margin:auto;
bottom:0;

}
.footerBar{
margin-top:14px;
}
.coverImage{

box-shadow: inset -7px 0 30px -7px rgba(0,0,0,.4);

}
.page.hard {
    background-color: #f2e8d9;
    border: 1px solid #998466
}

.page.page-cover {
    background-color: #2e3192;
    color: #FFF;
    border: 1px solid #998466
}

.page.page-cover h2 {
    text-align: center;
    padding-top: 30px;
    font-size: 210%
}

.page.page-cover.page-cover-top {
    box-shadow: inset 0 0 30px 0 rgba(36,10,3,.5),-2px 0 5px 2px rgba(0,0,0,.4)
}

.page.page-cover.page-cover-bottom {
    box-shadow: inset 0 0 30px 0 rgba(36,10,3,.5),10px 0 8px 0 rgba(0,0,0,.4)
}

/*# sourceMappingURL=main.a8ff09de.chunk.css.map */
.dd-page img{


position:absolute;top:0;height:auto;width:80%;
margin:auto;right:0;left:0;top:50px;
}

button,.buynow {
  background-color: #2e3192; /* Green */
  border: 2px solid white;;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius:20px;
font-weight:bold;
height:40px;
width:40px;
}
.buynow{

z-index:999;
position:absolute;
top:10px;;
right:120px;;
width:100px;
height:50px;;
font-size:20px;
padding:8px;

}
.container{
text-align:center;
}
a,a:hover{
text-decoration:none!important;

color:#fff;

}
.end{
font-size:12px;
margin-top:20px;
}

.pageholder{



margin-top:20px;

}



@media only screen and (max-width: 600px) {
 .buynow{

z-index:999;
position:absolute;
top:0px;;
right:0px;;
width:100%;
left:0;
height:100%;
font-size:20px;
padding:8px;
opacity:0;

}
}
.portrait .buynow{

right:0px;
top:auto;
left:0;
margin:auto;
bottom:18px;
}